const RegisterPage = {
    async render() {
        return `
            <style>
                .register-page {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    background-color: #f5f5f5;
                }

                .register-form {
                    width: 300px;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }

                .register-form h2 {
                    margin-bottom: 20px;
                    text-align: center;
                }

                .register-form label {
                    display: block;
                    margin-bottom: 10px;
                }

                .register-form input {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 15px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }

                .register-form button {
                    width: 100%;
                    padding: 10px;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .register-form button:hover {
                    background-color: #0056b3;
                }
            </style>

            <div class="register-page">
                <form class="register-form" id="register-form">
                    <h2>Register</h2>
                    <label for="username">Username:</label>
                    <input type="text" id="username" name="username">
                    <label for="password">Password:</label>
                    <input type="password" id="password" name="password">
                    <button type="submit" id="register-submit">Register</button>
                    <a href="/#/login">Login</a> <!-- Link to login page -->
                </form>
            </div>
        `;
    },

    async afterRender() {
        const registerForm = document.getElementById('register-form');
        registerForm.addEventListener('submit', async (event) => {
            event.preventDefault();

            const username = document.getElementById('username').value;
            const password = document.getElementById('password').value;

            try {
                const response = await fetch('https://rintara2024.site/api/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ username, password })
                });

                if (!response.ok) {
                    throw new Error('Registration failed. Please try again.');
                }

                alert('Registration successful. Please login.');
                window.location.href = '#/login';
            } catch (error) {
                console.error('An error occurred:', error.message);
                alert(error.message);
            }
        });
    }
};

export default RegisterPage;
