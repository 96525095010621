import { kategoriTemplate } from "../template/template-creator";

const ExplorePage = {
  async render() {
    return `
      <div class="explore-page">
        <h2 class="explore-title">Explore</h2>

        <div class="search-section">
          <div class="search-bar">
            <input type="text" id="search-input" placeholder="Search">
          </div>
        </div>

        <div class="kategori"></div>
      </div>
    `;
  },

  async afterRender() {
    const header = document.querySelector(".app-header");
    header.classList.add("scrolled");

    const container = document.querySelector('.kategori');
    const searchInput = document.querySelector('#search-input');

    let categories = [];

    const renderCategories = (data) => {
      container.innerHTML = '';
      data.forEach((kategori) => {
        container.innerHTML += kategoriTemplate(kategori);
      });
      
      // Add click event listener to each category
      document.querySelectorAll('.kategori-item').forEach(item => {
        item.addEventListener('click', () => {
          const id = item.getAttribute('data-id');
          window.location.href = `#/province/${id}`;
        });
      });
    };

    try {
      const response = await fetch('https://rintara2024.site/api/kategori');
      if (!response.ok) {
        throw new Error('Gagal mengambil data kategori');
      }
      categories = await response.json();
      renderCategories(categories); // Render initial categories
    } catch (error) {
      console.error('Terjadi kesalahan:', error.message);
    }

    searchInput.addEventListener('input', (event) => {
      const searchTerm = event.target.value.toLowerCase();
      const filteredCategories = categories.filter((kategori) =>
        kategori.title?.toLowerCase().includes(searchTerm) ||
        kategori.description?.toLowerCase().includes(searchTerm)
      );
      renderCategories(filteredCategories);
    });
  },
};

export default ExplorePage;
