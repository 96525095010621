const WriteArticlePage = {
    async render() {
        return `


        <style>
  .write-article-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }

  .write-article-card {
    width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .write-article-card h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  .write-article-card label {
    display: block;
    margin-bottom: 10px;
  }

  .write-article-card input,
  .write-article-card textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .write-article-card button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .write-article-card button:hover {
    background-color: #0056b3;
  }


   .write-article-card select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff; /* Warna latar belakang */
    color: #333; /* Warna teks */
    appearance: none; /* Menghapus default arrow pada select */
    cursor: pointer;
  }

  /* Styling untuk dropdown select saat di-hover */
  .write-article-card select:hover {
    border-color: #007bff; /* Warna border saat di-hover */
  }

  /* Styling untuk dropdown select saat di-focus */
  .write-article-card select:focus {
    outline: none; /* Menghapus outline saat di-focus */
    border-color: #007bff; /* Warna border saat di-focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Efek bayangan saat di-focus */
  }

</style>

        <hero-element></hero-element>
          <h2 style="text-align : center">Write Article</h2>
        <div class="write-article-page">
  <div class="write-article-card">
    <form id="write-article-form">
      <label for="title">Title:</label><br>
      <input type="text" id="title" name="title"><br>
      <label for="category">Category:</label><br>
    <select id="category" name="category"></select><br> 
       <label for="image-url">Image URL:</label><br>
      <input type="text" id="image-url" name="image-url"><br>
      <label for="content">Content:</label><br>
      <textarea id="content" name="content"></textarea><br><br>
      <button type="submit" id="submit-article">Submit</button>
    </form>
  </div>
</div>
      `;
    },

    async afterRender() {
        const writeArticleForm = document.getElementById('write-article-form');
        const categorySelect = document.getElementById('category');

        try {
            // Fetch data kategori
            const response = await fetch('https://rintara2024.site/api/kategori');
            if (!response.ok) {
                throw new Error('Failed to fetch categories.');
            }
            const data = await response.json();

            // Populate dropdown select with categories
            data.forEach(category => {
                const option = document.createElement('option');
                option.value = category.id_kategori;
                option.textContent = category.title;
                categorySelect.appendChild(option);
            });
        } catch (error) {
            console.error('An error occurred while fetching categories:', error.message);
            alert('Failed to fetch categories. Please try again.');
        }

        writeArticleForm.addEventListener('submit', async (event) => {
            event.preventDefault();

            const title = document.getElementById('title').value;
            const category = categorySelect.value;
            const imageUrl = document.getElementById('image-url').value;
            const content = document.getElementById('content').value;

            try {
                const response = await fetch('https://rintara2024.site/api/artikel', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id_kategori: category, title, image_url: imageUrl, body: content })
                });

                if (!response.ok) {
                    throw new Error('Failed to submit article. Please try again.');
                }

                alert('Article submitted successfully.');
                // Redirect to home page or any other page after submitting the article
                window.location.href = '/';
            } catch (error) {
                console.error('An error occurred:', error.message);
                alert(error.message);
            }
        });
    }
};

export default WriteArticlePage;
