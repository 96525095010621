import UrlParser from '../../routes/url-parser';

const ArticlePage = {
  async render() {
    return `
      <div class="article-page">
        <div class="article-content">
          <!-- Konten artikel akan dimuat di sini -->
        </div>
      </div>
    `;
  },

  async afterRender() {
    const header = document.querySelector(".app-header");
    header.classList.add("scrolled");

    // Ambil ID artikel dari URL
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const { id } = url;

    try {
      // Fetch data artikel berdasarkan ID
      const response = await fetch(`https://rintara2024.site/api/artikel/${id}`);
      if (!response.ok) {
        throw new Error('Gagal mengambil data artikel');
      }
      const artikel = await response.json();

      // Render artikel menggunakan data dari API
      const artikelContent = document.querySelector('.article-content');
      artikelContent.innerHTML = `
        <h1>${artikel.title}</h1>
        <img
          src="${artikel.image_url}"
          alt="${artikel.title}"
          class="featured-image"
        />
        <p>${artikel.body}</p>
      `;
    } catch (error) {
      console.error('Terjadi kesalahan:', error.message);
    }
  },
};

export default ArticlePage;
