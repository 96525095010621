import { provinceDetailTemplate, artikelTemplate, budayaTemplate } from "../template/template-creator";
import UrlParser from '../../routes/url-parser';

const ProvinceDetail = {
  async render() {
    return `
      <div class="province-detail">
        <!-- Konten detail provinsi akan dimuat di sini -->
      </div>
      <div class="budaya-detail province-main-content" style="display: flex;">
        <!-- Konten budaya akan dimuat di sini -->
      </div>
      <div class="search-container province-main-content" style="margin: 20px;">
        <input id="search-input" type="text" placeholder="Search articles" style="margin-bottom: 20px; padding: 10px; width: 100%;" />
      </div>
      <div class="artikel-list province-main-content" style="margin: 20px;">
        <!-- Daftar artikel akan dimuat di sini -->
      </div>
    `;
  },

  async afterRender() {
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const header = document.querySelector(".app-header");
    const container = document.querySelector('.province-detail');
    const artikelContainer = document.querySelector('.artikel-list');
    const budayaContainer = document.querySelector('.budaya-detail');
    const searchInput = document.querySelector('#search-input');

    if (header) {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
          header.classList.add("scrolled");
        } else {
          header.classList.remove("scrolled");
        }
      });
    }

    const { id } = url;

    try {
      const response = await fetch(`https://rintara2024.site/api/kategori/${id}`);
      if (!response.ok) {
        throw new Error('Gagal mengambil data kategori');
      }
      const data = await response.json();

      console.log('Data kategori:', data);

      if (data) {
        container.innerHTML = provinceDetailTemplate(data);
      } else {
        container.innerHTML = '<p>Data kategori tidak ditemukan</p>';
      }

      const responseArticles = await fetch(`https://rintara2024.site/api/artikel?kategori_id=${id}`);
      if (!responseArticles.ok) {
        throw new Error('Gagal mengambil data artikel');
      }
      const articlesData = await responseArticles.json();

      console.log('Data artikel:', articlesData);

      const allArticles = articlesData || [];
      const allBudaya = data.budaya || [];

      const renderArticles = (articles) => {
        artikelContainer.innerHTML = articles.map(artikelTemplate).join('');
      };

      const renderBudaya = (budaya) => {
        budayaContainer.innerHTML = budaya.map(budayaTemplate).join('');
      };

      renderArticles(allArticles);
      renderBudaya(allBudaya);

      if (searchInput) {
        searchInput.addEventListener('input', (event) => {
          const searchTerm = event.target.value.toLowerCase();
          const filteredArticles = allArticles.filter((artikel) =>
            artikel.title.toLowerCase().includes(searchTerm) ||
            artikel.body.toLowerCase().includes(searchTerm)
          );
          renderArticles(filteredArticles);
        });
      }

    } catch (error) {
      console.error('Terjadi kesalahan:', error.message);
      container.innerHTML = `<p>${error.message}</p>`;
    }
  },
};

export default ProvinceDetail;
