class AppBar extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    // Check apakah pengguna sudah login atau belum
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');

    this.innerHTML = `
      <div class="app-bar">
        <a href="" class="app-bar-logo">
          <h1>RinTara</h1>
        </a>
        <button type="button" id="hamburger" aria-label="navigation-menu" tabindex="0">☰</button>
        <nav id="navigationDrawer" class="app-bar-navigation">
          <ul>
            <li><a href="">Home</a></li>
            <li><a href="#/explore">Explore</a></li>
            <li><a href="#/about-us">About Us</a></li>
          </ul>
          <div class="user-login">
            ${isLoggedIn
        ? `
                  <a href="#" class="logout">Logout</a>
                  <a href="#/admin" class="write-article">Write Article</a>
                `
        : `
                  <a href="/#/login" class="login">Login</a>
                  <a href="/#/register" class="signup">Sign Up</a>
                `
      }
          </div>
        </nav>
      </div>
    `;

    // Tambahkan event listener untuk tombol Logout
    if (isLoggedIn) {
      const logoutButton = this.querySelector('.logout');
      logoutButton.addEventListener('click', () => {
        // Hapus status login dari session storage
        sessionStorage.removeItem('isLoggedIn');
        // Redirect ke halaman login
        window.location.href = '#/login';
      });
    }
  }
}

customElements.define('app-bar', AppBar);
