import UrlParser from '../../routes/url-parser';

function logout() {
    sessionStorage.removeItem('isLoggedIn');
    window.location.href = '#/login';
}

const AdminKategoriEditPage = {
    async render() {
        return `
        <style>
            /* admin-page.css */

            /* Header styling */
            .top-nav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                background-color: #AF8F6F;
                color: #fff;
            }

            .top-nav .logo {
                font-size: 1.5rem;
            }

            .logout-btn {
                padding: 0.5rem 1rem;
                background-color: #74512D;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s;
                border-radius: 0.5rem;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            }

            .logout-btn:hover {
                background-color: red;
            }

            /* Sidebar styling */
            .sidebar {
                width: 250px;
                background-color: rgba(175, 143, 111, 0.5);
                color: #ecf0f1;
                flex-shrink: 0;
                min-height: 100vh; /* Menggunakan min-height untuk memenuhi tinggi viewport */
            }

            .sidebar-nav {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }

            .sidebar-nav ul {
                padding: 0;
                margin: 0;
            }

            .sidebar-nav li {
                padding: 1rem;
            }

            .sidebar-nav li a {
                color: #59341A;
                text-decoration: none;
                display: block;
                transition: background-color 0.3s;
            }

            .sidebar-nav li a:hover {
                background-color: #AF8F6F;
            }

            /* Main content styling */
            .main-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1; /* Menggunakan flex-grow untuk mengisi sisa ruang di halaman */
                padding: 2rem;
                overflow-y: auto;
                background-color: #ecf0f1;
            }

            .content-section {
                display: block;
            }

            .content-section.active {
                display: block;
            }

            h2 {
                margin-top: 0;
            }

            /* Kategori form styling */
            #artikel-form {
                display: flex;
                flex-direction: column;
            }

            #artikel-form label {
                margin-top: 1rem;
            }

            #artikel-form input,#artikel-form select,
            #artikel-form textarea {
                margin-top: 0.5rem;
                padding: 0.5rem;
                font-size: 1rem;
                border: 1px solid #ccc;
                border-radius: 4px;
            }

            #artikel-form button {
                margin-top: 1rem;
                padding: 0.75rem;
                background-color: #693E16;
                width:20%;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s;
            }

            .content-flex {
                display: flex;
                flex-grow: 1; /* Menggunakan flex-grow untuk mengisi sisa ruang di halaman */
            }


            /* Article list styling */
            .article-item {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                background-color: #fff;
                padding: 1rem;
                border-radius: 4px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            }

            .article-item img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 4px;
                margin-right: 1rem;
            }

            .article-info {
                flex-grow: 1;
            }

            .article-info h3 {
                margin: 0 0 0.5rem 0;
            }

            .article-actions {
                display: flex;
                gap: 0.5rem;
            }

            .article-actions button {
                padding: 0.5rem 1rem;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s;
            }

            .article-actions button:first-child {
                background-color: #f39c12;
                color: #fff;
            }

            .article-actions button:first-child:hover {
                background-color: #e67e22;
            }

            .article-actions button:last-child {
                background-color: #e74c3c;
                color: #fff;
            }

            .article-actions button:last-child:hover {
                background-color: #c0392b;
            }

            /* Responsive styling */
            @media (max-width: 768px) {
                .container {
                    flex-direction: column;
                }

                .sidebar {
                    width: 100%;
                }

                .main-content {
                    padding: 1rem;
                }

                .article-item {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .article-item img {
                    margin-bottom: 1rem;
                }

                .content-flex {
                    flex-wrap: wrap;
                }
            }

        .create-btn:hover , .create-btn a:hover {
            background-color: rgb(51.76%, 42.75%, 40%);
        }


        /* Style untuk kartu */
        .card {
            padding:30px;
            background-color: #D9D9D9;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        </style>
        <div class="container-fluid">
            <header class="top-nav">
                <div class="logo">Admin</div>
                <button id="logout" class="logout-btn">Log Out <i class="fas fa-arrow-right"></i></button>
            </header>
            <div class="content-flex">
                <aside class="sidebar">
                    <h1 style="padding:20px"><a style="color:#59341A;text-decoration: none;" href="#/admin">Dashboard</a></h1>
                    <nav class="sidebar-nav">
                        <hr style="border-color: black;">
                          <ul>
                            <li><a href="#/admin-artikel">Artikel</a></li>
                            <li><a href="#/admin-kategori">kategori</a></li>
                        </ul>
                    </nav>
                </aside>
                <main class="main-content">
                    <section id="dashboard" class="content-section">
                    <h2>Edit Artikel</h2>
                    <div class="card">
                       <form id="artikel-form">
                             <label for="kategori">Kategori</label>
                            <select id="kategori" name="id_kategori">
                                <option value="">Pilih Kategori</option>
                            </select>
                            <label for="title">Title</label>
                            <input type="text" id="title" name="title" placeholder="title..." required>
                            <label for="image">Image</label>
                            <div>
                                <img style="width:200px" id="image-preview" src="" alt="Image">
                            </div>
                            <input type="text" id="image" name="image_url" placeholder="image url..." required>
                            <label for="body">Body</label>
                            <textarea id="body" name="body" rows="5" placeholder="body..."></textarea>
                            <div style="text-align: right;">
                                <button class="create-btn" type="submit">Simpan</button>
                            </div>
                        </form>
                    </div>
                    </section>
                </main>
            </div>
        </div>
    `;
    },

    logout() {
        sessionStorage.removeItem('isLoggedIn');
        window.location.href = '#/login';
    },

    async afterRender() {
        document.getElementById('logout').addEventListener('click', function () {
            logout();
        });

        const kategoriSelect = document.getElementById('kategori');

        async function fetchCategories() {
            try {
                const response = await fetch('https://rintara2024.site/api/kategori');
                if (!response.ok) {
                    throw new Error('Gagal mengambil data kategori');
                }

                const categories = await response.json();

                kategoriSelect.innerHTML = '<option value="">Pilih Kategori</option>'; // Menambahkan opsi default
                
                categories.forEach(category => {
                    const option = document.createElement('option');
                    option.value = category.id_kategori; // Sesuaikan dengan field ID kategori pada database Anda
                    option.textContent = category.title; // Sesuaikan dengan field nama kategori pada database Anda
                    kategoriSelect.appendChild(option);
                });
            } catch (error) {
                console.error('Terjadi kesalahan:', error.message);
            }
        }
        
        async function fetchData() {
            await Promise.all([fetchCategories()]);
        }

        fetchData();

        const headers = document.getElementsByClassName('app-header');
        for (let header of headers) {
            header.setAttribute('hidden', true);
        }

        const url = UrlParser.parseActiveUrlWithoutCombiner();
        const artikelId = url.id; // Anda harus menyesuaikan ini dengan cara Anda menangani URL

        // Fungsi untuk mengambil data kategori berdasarkan ID
        async function getArtikelById(id) {
            try {
                const response = await fetch(`https://rintara2024.site/api/artikel/${id}`);
                if (!response.ok) {
                    throw new Error('Gagal mendapatkan data artikel');
                }
                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Terjadi kesalahan:', error.message);
                throw error; // Propagasi error untuk ditangani di luar
            }
        }

        // Ambil data kategori berdasarkan ID dan isi nilai form
        try {
            const artikel = await getArtikelById(artikelId);
            document.getElementById('kategori').value = artikel.id_kategori;
            document.getElementById('title').value = artikel.title;
            document.getElementById('image').value = artikel.image_url;
            document.getElementById('image-preview').src = artikel.image_url;
            document.getElementById('body').value = artikel.body;
        } catch (error) {
            console.error('Terjadi kesalahan saat mengambil data artikel:', error.message);
        }

        const artikelForm = document.getElementById('artikel-form');

        artikelForm.addEventListener('submit', async function (e) {
            e.preventDefault();
            const formData = new FormData(artikelForm);
            const data = Object.fromEntries(formData.entries());

            try {
                const response = await fetch(`https://rintara2024.site/api/artikel/${artikelId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error('Gagal mengupdate artikel');
                }
                // Redirect ke halaman admin-kategori setelah berhasil diupdate
                window.location.href = '/#/admin-artikel';
            } catch (error) {
                console.error('Terjadi kesalahan:', error.message);
            }
        });

    },
};

export default AdminKategoriEditPage;