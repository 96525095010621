const recentItemTemplate = () => ``;

const exploreItemTemplate = () => ``;

const kategoriTemplate = (data) => `
  <a href="#/province-detail/${data.id_kategori}" class="kategori-items">
    <div class="kategori-img">
      <img src="${data.image_url}" alt="">
    </div>
    <p>${data.title}</p>
  </a>
`;

const getYoutubeEmbedUrl = (url) => {
  const videoId = url.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  return ampersandPosition !== -1 ? 
         `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}` : 
         `https://www.youtube.com/embed/${videoId}`;
};

const provinceDetailTemplate = (data) => {
  const embedUrls = [data.url1, data.url2, data.url3, data.url4].map(getYoutubeEmbedUrl);
  return `
    <styles>

    </styles>
    <div class="header-content">
      <img src="${data.image_url}" alt="${data.title}">
      <div class="overlay-text">
        <h1>${data.title}</h1>
        <p>${data.inides}</p>
      </div>
    </div>
  
    <div class="province-main-content">
      <section class="tentang">
        <h2>Tentang</h2>
        <p>Ibu Kota : ${data.ibu_kota}</p>
        <p>Bahasa : ${data.bahasa}</p>
        <p>Situs Sejarah : ${data.situs_sejarah}</p>
        <p>Tokoh Sejarah : ${data.tokoh_sejarah}</p>
        <p>Luas : ${data.luas}</p>
        <p>Title budaya : ${data.titlebudaya}</p>
        <p>Video Budaya :</p>
        <div class="video-container">
          ${embedUrls.map(url => `
            <iframe width="270" height="158" src="${url}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          `).join('')}
        </div>
      </section>
    </div>
  `;
};

const budayaTemplate = (budaya) => {
  const thumbnailUrl = getYoutubeThumbnailUrl(budaya.url);
  return `
    <div class="culture-card" style="margin:20px">
      <a href="${budaya.url}" target="_blank">
        <img src="${thumbnailUrl}" alt="Thumbnail" style="width:100%; height:auto; border-radius:8px; box-shadow:0 0 10px rgba(0, 0, 0, 0.1);">
      </a>
    </div>
  `;
};

const artikelTemplate = (artikel) => `
  <div class="article-item" style="margin-bottom : 10px">
    <span class="favorite">&#x2661;</span>
    <a href="#/article/${artikel.id_artikel}">
      <h3>${artikel.title}</h3>
    </a>
    <p class="release-date">Release date: ${new Date(artikel.insertAt).toLocaleDateString()}</p>
    <p>${artikel.body.substring(0, 200)}...</p>
  </div>
`;

export {
  recentItemTemplate,
  exploreItemTemplate,
  kategoriTemplate,
  provinceDetailTemplate,
  artikelTemplate,
  budayaTemplate
};
