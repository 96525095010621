const LoginPage = {
    async render() {
        return `
           <style>

           body{
              background-image: url("../public//images//hero/Hero-Image.jpg");
           }

    .login-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #f5f5f5;
    }

    .login-form {
        width: 300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .login-form h2 {
        margin-bottom: 20px;
        text-align: center;
    }

    .login-form label {
        display: block;
        margin-bottom: 10px;
    }

    .login-form input {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .login-form button {
        width: 100%;
        padding: 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .login-form button:hover {
        background-color: #0056b3;
    }
</style>

<div class=".hero-element">

<div class="login-page">
    <form class="login-form" id="login-form">
        <h2>Login</h2>
        <label for="username">Username:</label>
        <input type="text" id="username" name="username">
        <label for="password">Password:</label>
        <input type="password" id="password" name="password">
        <button type="submit" id="login-submit">Login</button>
        <a href="/#/register">Register</a>
    </form>
</div>

</div>

        `;
    },

    async afterRender() {
        const loginForm = document.getElementById('login-form');
        loginForm.addEventListener('submit', async (event) => {
            event.preventDefault(); // Prevent form submission

            const username = document.getElementById('username').value;
            const password = document.getElementById('password').value;

            try {
                // Kirim data login ke server
                const response = await fetch('https://rintara2024.site/api/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ username, password })
                });

                if (!response.ok) {
                    throw new Error('Login gagal. Silakan coba lagi.');
                }

                // Simpan status login di session atau cookie
                sessionStorage.setItem('isLoggedIn', 'true');

                // Redirect ke halaman setelah login berhasil
                window.location.href = '/#/admin'; // Ganti dengan halaman yang sesuai
            } catch (error) {
                console.error('Terjadi kesalahan:', error.message);
                alert(error.message); // Tampilkan pesan error
            }
        });
    }
};

export default LoginPage;
