import UrlParser from '../../routes/url-parser';


function logout() {
    sessionStorage.removeItem('isLoggedIn');
    window.location.href = '#/login';
}

const AdminPage = {
    async render() {
        return `
        <style>
            /* admin-page.css */

/* General container styling */
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* Header styling */
.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color:#AF8F6F;
    color: #fff;
}

.top-nav .logo {
    font-size: 1.5rem;
}

.logout-btn {
    padding: 0.5rem 1rem;
    background-color: #74512D;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0.5rem; 
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.logout-btn:hover {
    background-color: red;
}

  /* Sidebar styling */
            .sidebar {
                width: 250px;
                background-color: rgba(175, 143, 111, 0.5);
                color: #ecf0f1;
                flex-shrink: 0;
                min-height: 100vh; /* Menggunakan min-height untuk memenuhi tinggi viewport */
            }

            .sidebar-nav {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }

            .sidebar-nav ul {
                padding: 0;
                margin: 0;
            }

            .sidebar-nav li {
                padding: 1rem;
            }

            .sidebar-nav li a {
                color: #59341A;
                text-decoration: none;
                display: block;
                transition: background-color 0.3s;
            }

            .sidebar-nav li a:hover {
                background-color: #AF8F6F;
            }

/* Main content styling */
.main-content {
    flex-grow: 1;
    padding: 2rem;
    overflow-y: auto;
    background-color: #ecf0f1;
}

.content-section {
    display: block;
}

.content-section.active {
    display: block;
}

h2 {
    margin-top: 0;
}

/* Article form styling */
#article-form {
    display: flex;
    flex-direction: column;
}

#article-form label {
    margin-top: 1rem;
}

#article-form input,
#article-form textarea {
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#article-form button {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #2980b9;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}


.content-flex{
display : flex
}

#article-form button:hover {
    background-color: #3498db;
}

/* Article list styling */
.article-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.article-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 1rem;
}

.article-info {
    flex-grow: 1;
}

.article-info h3 {
    margin: 0 0 0.5rem 0;
}

.article-actions {
    display: flex;
    gap: 0.5rem;
}

.article-actions button {
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.article-actions button:first-child {
    background-color: #f39c12;
    color: #fff;
}

.article-actions button:first-child:hover {
    background-color: #e67e22;
}

.article-actions button:last-child {
    background-color: #e74c3c;
    color: #fff;
}

.article-actions button:last-child:hover {
    background-color: #c0392b;
}

/* Responsive styling */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
    }

    .main-content {
        padding: 1rem;
    }

    .article-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-item img {
        margin-bottom: 1rem;
    }
        .content-flex{
flex-wrap : wrap
}
}

.card-container {
    display: flex;
    justify-content: center;
    gap: 40px; /* Jarak antara kedua card */
    margin-top: 20px; /* Jarak dari judul h3 */
}

.card {
    text-align: center;
    background-color: #f0f0f0; /* Warna latar belakang card */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Efek bayangan card */
}

.card img {
    max-width: 100%; /* Agar gambar tidak melebihi lebar card */
    border-radius: 8px; /* Memastikan gambar memiliki sudut bulat yang sesuai */
}

.card h4 {
    margin-top: 10px; /* Jarak antara gambar dan judul */
    color: #59341A; /* Warna teks judul */
}

        </style>
        <div class="container">
            <header class="top-nav">
                <div class="logo">Admin</div>
              <button id="logout" class="logout-btn">Log Out <i class="fas fa-arrow-right"></i> </button>
            </header>
            <div class="content-flex">
                <aside class="sidebar">
                <h1 style="padding:20px"><a style="color:#59341A;text-decoration: none;" href="#/admin">Dashboard</a></h1>
                    <nav class="sidebar-nav">
                    <hr style="border-color: black;">
                        <ul>
                            <li><a href="#/admin-artikel">Artikel</a></li>
                             <li><a href="#/admin-kategori">kategori</a></li>
                        </ul>
                    </nav>
                </aside>
                <main class="main-content">
                    <section id="dashboard" class="content-section">
                        <h3 style="text-align: center;color:#59341A">Dashboard Admin</h3>

                        <div class="card-container">
                            <div class="card">
                                <img src="./images/images3.png" style="width:300px" alt="Image">
                                <h4><a href="#/admin-artikel" style="text-decoration:none;color:#59341A">Artikel</a></h4>
                            </div>
                            <div class="card">
                               <img src="./images/images3.png" style="width:300px" alt="Image">
                                 <h4><a href="#/admin-kategori" style="text-decoration:none;color:#59341A">Kategori</a></h4>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    `;
    },


    logout() {
        sessionStorage.removeItem('isLoggedIn');
        window.location.href = '#/login';
    },

    async afterRender() {


        document.getElementById('logout').addEventListener('click', function () {
            logout();
        })

        const headers = document.getElementsByClassName('app-header');
        for (let header of headers) {
            header.setAttribute('hidden', true);
        }
        function logout() {
            sessionStorage.removeItem('isLoggedIn');
            window.location.href = '#/login';
        }

    },
};

export default AdminPage;


