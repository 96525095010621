import ExplorePage from '../views/pages/explore-page';
import HomePage from '../views/pages/home-page';
import AboutUs from '../views/pages/aboutUs-page';
import ArticlePage from '../views/pages/article-page';
import ProvinceDetail from '../views/pages/provinceDetail-page';
import LoginPage from '../views/pages/login-page';
import RegisterPage from '../views/pages/register-page';
import WriteArticlePage from '../views/pages/write-artikel';
import AdminPage from '../views/pages/admin-page';
import AdminKategoriPage from '../views/pages/admin-kategori-page';
import AdminKategoriCreatePage from '../views/pages/admin-kategori-create-page';
import AdminKategoriEditPage from '../views/pages/admin-kategori-edit-page';
import AdminArtikelPage from '../views/pages/admin-artikel-page';
import AdminArtikelCreatePage from '../views/pages/admin-artikel-create-page';
import AdminArtikelEditPage from '../views/pages/admin-artikel-edit-page';



const routes = {
  '/': HomePage,
  '/explore': ExplorePage,
  '/about-us': AboutUs,
  '/province-detail/:id': ProvinceDetail,
  '/article/:id': ArticlePage,
  "/login": LoginPage,
  "/register": RegisterPage,
  "/write-article": WriteArticlePage,
  '/admin': AdminPage,
  '/admin-artikel': AdminArtikelPage,
  '/admin-artikel-create': AdminArtikelCreatePage,  
  '/admin-artikel-edit/:id': AdminArtikelEditPage,
  '/admin-kategori': AdminKategoriPage,
  '/admin-kategori-create': AdminKategoriCreatePage,  
  '/admin-kategori-edit/:id': AdminKategoriEditPage
};

export default routes;
