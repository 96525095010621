import UrlParser from '../../routes/url-parser';

function logout() {
    sessionStorage.removeItem('isLoggedIn');
    window.location.href = '#/login';
}

const AdminArtikelPage = {
    async render() {
        return `
        <style>
            /* admin-page.css */

            /* Header styling */
            .top-nav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                background-color: #AF8F6F;
                color: #fff;
            }

            .top-nav .logo {
                font-size: 1.5rem;
            }

            .logout-btn {
                padding: 0.5rem 1rem;
                background-color: #74512D;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s;
                border-radius: 0.5rem;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            }

            .logout-btn:hover {
                background-color: red;
            }

            /* Sidebar styling */
            .sidebar {
                width: 250px;
                background-color: rgba(175, 143, 111, 0.5);
                color: #ecf0f1;
                flex-shrink: 0;
                min-height: 100vh; /* Menggunakan min-height untuk memenuhi tinggi viewport */
            }

            .sidebar-nav {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }

            .sidebar-nav ul {
                padding: 0;
                margin: 0;
            }

            .sidebar-nav li {
                padding: 1rem;
            }

            .sidebar-nav li a {
                color: #59341A;
                text-decoration: none;
                display: block;
                transition: background-color 0.3s;
            }

            .sidebar-nav li a:hover {
                background-color: #AF8F6F;
            }

            /* Main content styling */
            .main-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1; /* Menggunakan flex-grow untuk mengisi sisa ruang di halaman */
                padding: 2rem;
                overflow-y: auto;
                background-color: #ecf0f1;
            }

            .content-section {
                display: block;
            }

            .content-section.active {
                display: block;
            }

            h2 {
                margin-top: 0;
            }

            /* Article form styling */
            #article-form {
                display: flex;
                flex-direction: column;
            }

            #article-form label {
                margin-top: 1rem;
            }

            #article-form input,
            #article-form textarea {
                margin-top: 0.5rem;
                padding: 0.5rem;
                font-size: 1rem;
                border: 1px solid #ccc;
                border-radius: 4px;
            }

            #article-form button {
                margin-top: 1rem;
                padding: 0.75rem;
                background-color: #2980b9;
                color: #fff;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s;
            }

            .content-flex {
                display: flex;
                flex-grow: 1; /* Menggunakan flex-grow untuk mengisi sisa ruang di halaman */
            }

            #article-form button:hover {
                background-color: #3498db;
            }

            /* Article list styling */
            .article-item {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                background-color: #fff;
                padding: 1rem;
                border-radius: 4px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            }

            .article-item img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 4px;
                margin-right: 1rem;
            }

            .article-info {
                flex-grow: 1;
            }

            .article-info h3 {
                margin: 0 0 0.5rem 0;
            }

            .article-actions {
                display: flex;
                gap: 0.5rem;
            }

            .article-actions button {
                padding: 0.5rem 1rem;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s;
            }

            .article-actions button:first-child {
                background-color: #f39c12;
                color: #fff;
            }

            .article-actions button:first-child:hover {
                background-color: #e67e22;
            }

            .article-actions button:last-child {
                background-color: #e74c3c;
                color: #fff;
            }

            .article-actions button:last-child:hover {
                background-color: #c0392b;
            }

            /* Responsive styling */
            @media (max-width: 768px) {
                .container {
                    flex-direction: column;
                }

                .sidebar {
                    width: 100%;
                }

                .main-content {
                    padding: 1rem;
                }

                .article-item {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .article-item img {
                    margin-bottom: 1rem;
                }

                .content-flex {
                    flex-wrap: wrap;
                }
            }

        .table {
            width: 100%;
            border-collapse: collapse;
            background-color: #D9D9D9;
        }

        .table th, .table td {
            padding: 12px;
            text-align: left;
            border: 1px solid black;
        }

        .table th {
            background-color: #f2f2f2;
            color: #333;
        }

        .table td button {
            padding: 8px 12px;
            margin-right: 6px;
            cursor: pointer;
            border: none;
            background-color: rgb(51.76%, 42.75%, 25.49%);
            color: #fff;
            border-radius: 4px;
            transition: background-color 0.3s;
        }

        .create-btn, .create-btn a {
            padding: 8px 12px;
            text-decoration: none;
            margin-right: 6px;
            margin-bottom: 12px;
            cursor: pointer;
            border: none;
            background-color: rgb(51.76%, 42.75%, 25.49%);
            color: #fff;
            border-radius: 4px;
            transition: background-color 0.3s;
        }

        .create-btn:hover , .create-btn a:hover {
            background-color: rgb(51.76%, 42.75%, 40%);
        }

        .table td button:hover {
            background-color: rgb(51.76%, 42.75%, 40%);
        }

        /* Style untuk kartu */
        .card {
            padding:30px;
            background-color: #D9D9D9;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-top: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        </style>
        <div class="container-fluid">
            <header class="top-nav">
                <div class="logo">Admin</div>
                <button id="logout" class="logout-btn">Log Out <i class="fas fa-arrow-right"></i></button>
            </header>
            <div class="content-flex">
                <aside class="sidebar">
                    <h1 style="padding:20px"><a style="color:#59341A;text-decoration: none;" href="#/admin">Dashboard</a></h1>
                    <nav class="sidebar-nav">
                        <hr style="border-color: black;">
                         <ul>
                            <li><a href="#/admin-artikel">Artikel</a></li>
                            <li><a href="#/admin-kategori">kategori</a></li>
                        </ul>
                    </nav>
                </aside>
                <main class="main-content">
                    <section id="dashboard" class="content-section">
                    <h2>Artikel</h2>
                    <div class="card">
                       <button class="create-btn">
                           <a href="#/admin-artikel-create">
                                <i class="fas fa-plus"></i> Create
                            </a>
                        </button>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ID Artikel</th>
                                    <th>Kategori</th>
                                    <th>Title</th>
                                    <th>Insert At</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody id="artikel-list">
                                <!-- Data artikel akan dimasukkan disini -->
                            </tbody>
                        </table>
                    </div>
                    </section>
                </main>
            </div>
        </div>
    `;
    },

    logout() {
        sessionStorage.removeItem('isLoggedIn');
        window.location.href = '#/login';
    },

    async afterRender() {
        document.getElementById('logout').addEventListener('click', function () {
            logout();
        });

        const headers = document.getElementsByClassName('app-header');
        for (let header of headers) {
            header.setAttribute('hidden', true);
        }


        async function fetchData() {
            await Promise.all([fetchCategories()]);
        }

        async function fetchCategories() {
            try {
                const response = await fetch('https://rintara2024.site/api/artikel');
                if (!response.ok) {
                    throw new Error('Gagal mengambil data artikel');
                }
                const categories = await response.json(); // Mengambil data dari response

                const artikelList = document.getElementById('artikel-list');
                artikelList.innerHTML = ''; // Mengosongkan isi tbody terlebih dahulu

                categories.forEach((data) => {
                    const row = document.createElement('tr');
                    row.innerHTML = `
                        <td>${data.id_artikel}</td>
                        <td>${data.id_kategori}</td>
                        <td>${data.title}</td>
                        <td>${data.insertAt}</td>
                        <td>
                            <button class="edit-btn" data-id="${data.id_artikel}">
                                <i class="fas fa-edit"></i> Edit
                            </button>

                            <button class="delete-btn" data-id="${data.id_artikel}">
                                <i class="fas fa-trash-alt"></i> Hapus
                            </button>
                        </td>
                    `;

                    // Tambahkan event listener untuk tombol edit dan hapus
                    const editBtn = row.querySelector('.edit-btn');
                    const deleteBtn = row.querySelector('.delete-btn');

                    editBtn.addEventListener('click', () => {
                        // Logika untuk menghandle edit kategori
                        console.log(`Edit kategori dengan ID ${data.id_artikel}`);

                        window.location.href = `/#/admin-artikel-edit/${data.id_artikel}`
                        
                    });

                    deleteBtn.addEventListener('click', async () => {
                        try {
                            const response = await fetch(`https://rintara2024.site/api/artikel/${data.id_artikel}`, {
                                method: 'DELETE'
                            });
                            if (!response.ok) {
                                throw new Error('Gagal menghapus artikel');
                            }
                            fetchData(); // Misalnya ini adalah fungsi untuk memuat ulang data setelah penghapusan
                        } catch (error) {
                            console.error('Terjadi kesalahan:', error.message);
                        }
                    });
                    

                    artikelList.appendChild(row);
                });
            } catch (error) {
                console.error('Terjadi kesalahan:', error.message);
            }
        }

        fetchData();
    },
};

export default AdminArtikelPage;



